var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-textarea',{ref:"input-text-area",staticClass:"pt-0",class:{ 'mt-3': !_vm.dense },attrs:{"id":_vm.id,"label":_vm.label,"loading":_vm.loading,"disabled":_vm.disabled,"hide-details":_vm.hideDetails,"outlined":"","rows":_vm.rows,"rules":[
		(v) => (v && !_vm.hideDetails ? v.length <= 250 : true) || `${_vm.placeholder} maximum 250 characters`,
		(v) =>
			(v && !_vm.hideDetails ? v.split(/\r|\r\n|\n/).length <= 20 : true) ||
			`${_vm.placeholder} maximum 10 Line`,
		..._vm.rules,
	],"placeholder":_vm.placeholder,"auto-grow":"","counter":_vm.counter,"counter-value":(v) => (v ? v.length : 0),"hint":`Max character limit ${_vm.counter}`,"persistent-hint":""},on:{"change":function($event){return _vm.$emit('change', _vm.textareainput)},"keyup":function($event){return _vm.$emit('keyup', _vm.textareainput)},"keypress":function($event){return _vm.$emit('keypress', _vm.textareainput)},"blur":function($event){return _vm.$emit('blur', _vm.textareainput)}},model:{value:(_vm.textareainput),callback:function ($$v) {_vm.textareainput=$$v},expression:"textareainput"}})
}
var staticRenderFns = []

export { render, staticRenderFns }